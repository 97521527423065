<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="true" :show-backdrop="true" @didDismiss="dismissModal">
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="h-100 px-2" style="overflow: auto">
        <form @submit.prevent="submitProfile">
          <div class="mb-3"><i> Please fill out the below form!</i></div>

          <div class="my-2"><strong>Name</strong></div>

          <ion-input v-model.trim="orderForm.name" class="c-input" placeholder="Enter your name." required />

          <div class="my-2"><strong>Email</strong></div>

          <ion-input
            v-model.trim="orderForm.email"
            class="c-input"
            placeholder="Enter your email address."
            autocapitalize="off"
            type="email"
            required
          />

          <div class="my-2">
            <strong>What are you looking for?</strong>
          </div>
          <ion-textarea
            v-model="orderForm.text"
            rows="3"
            class="c-textarea mb-3"
            placeholder="Share what you're looking for."
            required
          />

          <div class="w-100 d-flex justify-content-center">
            <ion-button
              v-if="get(user, 'is_email_verified', true)"
              class="submit-btn"
              :disabled="isSubmitting || !isFormComplete"
              type="submit"
            >
              <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
              <span v-else>Submit</span>
            </ion-button>
            <ion-button
              v-else
              class="submit-btn"
              :disabled="isSubmitting || !isFormComplete"
              @click="openVerificationModal"
            >
              <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
              <span v-else>Submit</span>
            </ion-button>
          </div>
        </form>
      </div>
    </modals-layout>
    <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { toast } from '@/shared/native';
import { continueMPOrder } from '@/shared/actions/marketplace';
import { authStore } from '@/shared/pinia-store/auth';
import VerificationModal from '@/shared/modals/VerificationModal.vue';

@Options({
  name: 'ContinueOrderEmail',
  components: { VerificationModal },
})
export default class ContinueOrderEmail extends Vue {
  @Prop({ default: () => 'Continue with Order', required: false }) modalTitle!: string;
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({ required: true }) listingId!: string;

  isSubmitting = false;
  orderForm = {
    name: '',
    email: '',
    text: '',
  };

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public verificationModal = false;

  public openVerificationModal = () => {
    this.verificationModal = true;
  };

  public closeVerificationModal = () => {
    this.verificationModal = false;
  };

  @Watch('isOpen')
  isOpenChanged(value: boolean) {
    if (value) {
      this.orderForm.name = this.orderForm.text = this.orderForm.email = '';
    }
  }

  public get isFormComplete() {
    return !!this.orderForm.name && !!this.orderForm.email && !!this.orderForm.text;
  }

  public dismissModal() {
    this.$emit('dismissModal');
  }

  public async submitProfile() {
    if (!this.isFormComplete) return;

    this.isSubmitting = true;
    try {
      const data = await continueMPOrder({
        listing: this.listingId,
        email: this.orderForm.email,
        name: this.orderForm.name,
        text: this.orderForm.text,
      });
      await toast.show('Order request sent. Please check your email for next steps.', 'nonative', 'success');
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }
    this.dismissModal();
    this.isSubmitting = false;
  }
}
</script>

<style lang="sass" scoped>
.submit-btn
  width: 180px
  .spinner
    width: 15px
    height: 15px

ion-modal
  @media(max-width: 599px) and (max-height: 767px)
    --height: 85%
    --width: 85%
</style>
