<template>
  <ion-page class="page scrollable">
    <div v-if="isLoading" class="d-flex justify-content-center align-items-center">
      <ChLoading size="sm" class="spinner" />
    </div>

    <div v-else class="wrapper">
      <div class="d-flex justify-content-between mt-3">
        <div>
          <router-link :to="{ name: 'marketplace' }" class="clickable-item-hov d-flex align-items-center text-black"
            ><i class="ti-angle-left mr-1" />Back to Marketplace</router-link
          >
        </div>
        <div></div>
        <div v-if="user.id !== listing.seller?.id">
          <ion-button class="header-popover-button d-block" @click.stop="dropdownToggle"
            ><i class="ti-more-alt"
          /></ion-button>
        </div>
      </div>
      <div class="d-flex align-items-center flex-wrap mr-2 pt-3">
        <div class="title mr-2">{{ listing.title }}</div>

        <ion-badge
          v-if="user.id === listing.seller?.id"
          :color="!listing.is_active ? 'danger' : 'success'"
          class="mr-2"
          >{{ !listing.is_active ? 'Paused' : 'Active' }}</ion-badge
        >

        <MatureTag size="md" v-if="listing.is_nsfw" color="danger" class="mr-1" />
        <ion-button v-if="isOwner" color="transparent" class="edit-btn inline-button" @click="editing">
          Edit
        </ion-button>
      </div>

      <div class="mt-2">
        <router-link
          :to="{
            name: 'profile',
            params: { username: get(listing, 'seller.username') },
          }"
          >{{ get(listing, 'seller.username') }}</router-link
        >
      </div>

      <ClientOnly>
        <swiper
          :modules="[SwiperNavigation, SwiperPagination]"
          navigation
          :pagination="{ clickable: true }"
          :slides-per-view="1"
          :space-between="50"
          class="visuals mt-3"
        >
          <swiper-slide
            v-for="(visual, index) in allVisuals"
            :key="`vis-${index}`"
            class="d-flex align-items-center justify-content-center"
            ><img loading="lazy" v-image class="justify-self-center" :src="resizeUpload(visual, '800w')"
          /></swiper-slide>
          ...
        </swiper>
      </ClientOnly>

      <div class="sub_title mt-3">
        Base Price:
        <span v-if="listing.base_price === 0">Free</span>

        <span v-else
          ><inline-svg v-if="get(listing, 'currency_type') === 'gem'" src="/gem.svg" class="svg" /> <span v-else>$</span
          >{{ listing.base_price }}</span
        >
      </div>
      <div class="sub_title mt-3">Description:</div>
      <div v-html="sanitizeHtml(listing.description || '')" class="description mx-auto mt-1"></div>

      <div class="section mt-4 clickable-item">
        <ion-badge v-for="tag in listing.tags" :key="tag.id" color="medium ml-2" @click="openTagSearch(tag)"
          >#{{ tag }}</ion-badge
        >
      </div>

      <div class="sub_title mt-3">Do's And Don'ts:</div>
      <div v-html="sanitizeHtml(listing.usage_term || '')" class="description mx-auto mt-1"></div>

      <div v-if="get(categoriesByVal, listing.category || '')" class="sub_title mt-3">Category:</div>
      <div class="description mx-auto mt-1">
        {{ get(categoriesByVal[listing.category || ''], 'name') }}
      </div>

      <div class="mt-3 px-5" v-if="!isOwner">
        <ion-button class="continue-btn" @click="continueOrder">Request More Details </ion-button>
      </div>

      <continue-order-email :is-open="isFormOpen" :listing-id="listing.id" @dismiss-modal="closeForm" />
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { toast } from '@/shared/native';
import { MarketplaceListing } from '@/shared/types/static-types';
import ContinueOrderEmail from '@/shared/modals/ContinueOrderEmail.vue';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';
import store from '@/shared/store';
import MarketplaceListingPopover from './popovers/MarketplaceListingPopover.vue';
import MatureTag from '@/shared/components/MatureTag.vue';
import { popovers } from '@/shared/native/popovers';
import { sanitizeHtml } from '@/shared/utils/html';
import { alertController } from '@ionic/vue';

const picture = ref('');
const title = ref('');
const description = ref('');
const pageTitle = ref('');
const isPublic = ref(true);
const route = useRoute();
const url = ref(`https://characterhub.com${route.path}`);

const { user, isAuthenticated } = authStore();

const isOwner = computed(() => user.value?.id === listing.value.seller?.id);

const getListingFromSlug = (args: any) => store.dispatch('ListingModule/getListingFromSlug', args);

const listing = ref<MarketplaceListing>({
  title: '',
  base_price: 0,
  tags: [],
});

const allVisuals = ref<any[]>([]);
const isFormOpen = ref(false);

const isLoading = ref(true);

const categoriesByVal = keyBy(constants.mpListingCategories, 'value');

const listingSlug = computed(() => {
  const router = useRouter();
  return router.currentRoute.value.params.slug;
});

const dropdownToggle = (ev: CustomEvent) => {
  popovers.open(ev, MarketplaceListingPopover, { listing: listing.value });
};

const fetchListing = async (slug?: string) => {
  const router = useRouter();
  isLoading.value = true;
  try {
    const document = useDocument();
    const resp = await getListingFromSlug(slug || listingSlug.value);
    listing.value = resp;
    allVisuals.value = resp.visuals;

    if (!document.value) return;

    document.value.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  } catch (e) {
    await toast.show(`Couldn't load listing`, 'nonative', 'danger');
    router.go(-1);
  }
  isLoading.value = false;
};

const continueOrder = async () => {
  isFormOpen.value = true;
};

const openTagSearch = (tagText: any) => {
  const router = useRouter();
  const hashText = '#';
  tagText = hashText.concat(tagText);
  router.push({ name: 'search', query: { term: tagText, tab: 'all' } });
};

const closeForm = () => {
  isFormOpen.value = false;
};

const editing = () => {
  const router = useRouter();
  router.push({ name: 'edit-mp-listing', params: { id: listing.value.id } });
};

await useChAsyncData(async () => {
  await fetchListing();
  pageTitle.value = `${listing.value.title} - CharacterHub`;
  description.value = `Read more about ${listing.value.title} on CharacterHub!`;
  title.value = `${listing.value.title}`;
  picture.value = listing?.value?.visuals?.length ? listing?.value?.visuals[0] : '';
  isPublic.value = !!listing?.value?.is_active;

  useChHead(pageTitle, title, description, url, picture, isPublic);

  if (process.client) await nsfwWarning();
});

const nsfwWarning = async () => {
  if (listing.value) {
    if (!isAuthenticated.value && (listing.value.is_nsfw || listing?.value?.seller?.is_nsfw)) {
      const alert = await alertController.create({
        cssClass: 'char-warning-alert',
        header: 'Warning',
        message: `You are about to see potentially mature content, by proceeding you confirm you are 18 years of age or older.`,
        buttons: [
          {
            text: 'Continue',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'mx-auto',
          },
        ],
        backdropDismiss: false,
      });
      await alert.present();
    }
  }
};
</script>
<style lang="sass" scoped>
.svg
  width: 20px
  height: 20px

.header-popover-button
  position: absolute
  right: 1.5rem
  width: 32px
  --border-radius: 16px
  --background: #989aa2
  height: 16px

  i
    font-size: 12px
    color: white
.description
  font-size: 14px
  white-space: pre-wrap

.title
  font-size: 24px
  font-weight: bold

.sub_title
  font-size: 20px
  font-weight: bold

.wrapper
  padding: 0 15px

.edit-btn
  font-size: 14px
  color: #7f10b3
  font-weight: bold

.continue-btn
  width: 100%

.spinner
  width: 40px
  height: 40px

.visuals
  height: 300px
  @media (max-width: 480px)
    height: 200px
</style>
